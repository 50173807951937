import ovp from '#/providers/ovp';
import { assetTypes } from '#/models/assetTypes';
import EpgContentBlockModelBuilder from '#/models/live/EpgContentBlock';
import LiveProgramBuilder from '#/models/live/liveProgram';
import fetcher from '../../helpers/fetcher';

const CHANNEL_COUNT = 20;

/**
 * @module services/ovp
 * @description
 * Service to handle the fetch of ovp live data
 */

const getTvListings = async ({ startTime, endTime, count, offset }) => {
  const data = await fetcher({
    fetchFn: () => ovp.getTvListings({ startTime, endTime, count, offset })
  });
  return new EpgContentBlockModelBuilder(data).create();
};

/**
 * Retrieves a pretty version of hours and minutes information
 * from a timestamp. (HH:MM)
 * @param {Number} unixTimestamp The unix timestamp to get the value from
 * @returns {String} The hours and minutes in the format HH:SS
 */
const getTimeHoursMins = unixTimestamp => {
  const date = new Date(unixTimestamp);
  const hours = `0${date.getHours()}`;
  const minutes = `0${date.getMinutes()}`;

  return `${hours.slice(-2)}:${minutes.slice(-2)}`;
};

/**
 * Fetches information for live data between star time and end time.
 * @param {Number} startTime The start time to fetch information (unix timestamp in ms)
 * @param {Number} endTime The end time to fetch information (unix timestamp in ms)
 * @returns {Array<Object>} Live programs
 */
const getLiveData = async (startTime, endTime) => {
  const channels = await fetcher({
    fetchFn: () => ovp.getChannelData()
  });
  const count = channels.totalCount || CHANNEL_COUNT;
  const data = await getTvListings({ startTime, endTime, count });

  return data.entries.map(record => {
    const { programs, images } = record;
    const channel = images && images.length > 0 ? images[0] : null;
    const curProgram = programs.length > 0 ? programs[0] : null;
    const nextProgram = programs.length > 1 ? programs[1] : null;

    const curStartTime = getTimeHoursMins(curProgram?.startTime);
    const curEndTime = getTimeHoursMins(curProgram?.endTime);
    const nextStartTime = getTimeHoursMins(nextProgram?.startTime);
    const nextEndTime = getTimeHoursMins(nextProgram?.endTime);

    return {
      id: record.id,
      type: assetTypes.LIVE_CHANNEL,
      channel,

      curProgram,
      nextProgram,

      curTitle: curProgram?.title || '',
      curStartTime,
      curEndTime,

      nextTitle: nextProgram?.title || '',
      nextStartTime,
      nextEndTime
    };
  });
};

const getProgramData = async (channel, startTime) => {
  const tvListingsData = await ovp.getChannelTvListings({ channel, startTime });

  const liveProgram = new LiveProgramBuilder(tvListingsData);

  return liveProgram.create();
};

export { getLiveData, getTvListings, getProgramData };
