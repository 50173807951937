export default {
  // testing and example strings
  title: 'My title',
  description: 'My description',
  interpolate: 'This is a {{adjetive}} string',
  plurals: 'I have just {{smart_count}} key |||| I have {{smart_count}} keys',
  // real strings

  // Login Screen
  emailHint: 'Username',
  passwordHint: 'Password',
  login: 'login',
  forgotPassword: 'forgot password',
  pairing: 'Pair Device',
  cancelButton: 'cancel',
  okButton: 'ok',
  loginEmptyDescription: 'Empty username or password. Please fill both fields.',
  loginErrorDescription: 'Incorrect username or password. Please try again.',

  // Pairing Screen
  pairingTitle: 'Pair Code:',
  pairingVisit: 'Visit',
  pairingUseCode: 'and use the pair code above',
  pairingToPair: 'to pair your device',
  pairingObtaining: 'obtaining the code...',

  // ResetPassword Screen
  resetPasswordTitle: 'Reset your password',
  resetPasswordSubTitle:
    "Enter the email that is linked to your account. We'll send you an email with a link to reset your password.",
  sendAction: 'Send',
  resetPasswordErrorInputTitle: 'Empty username',
  resetPasswordErrorInputSubTitle: 'Please fill username field.',
  resetPasswordErrorResultTitle: 'Unable to find',
  resetPasswordErrorResultSubTitle: 'Please try again.',
  resetPasswordSuccessTitle: 'Email sent to',
  resetPasswordSuccessSubTitle:
    "We've sent you an email to reset your password.",

  // Info Screen
  about: 'about Elevate Designs',
  termsOfUse: 'terms of use',
  helpFaq: 'help & faq',
  contactUs: 'contact us',
  // Profile Screen
  editProfile: 'profile',
  logout: 'logout',
  logOutQuestion: 'Log out?',
  detailedLogoutQuestion: 'Are you sure you want to logout?',
  // EPG Screen
  backToSchedule: 'Back to schedule',
  watching: 'Watching',
  watchNowButton: 'Watch now',
  backButton: 'Back to navigation',
  epgEntitlementPopupTitle: 'Not entitled',
  epgEntitlementPopupContent:
    'You need to subscribe to the channel before you can watch it.',
  epgLoginPopupTitle: 'Login',
  epgLoginPopupContent1: 'You need to be logged in to watch a channel.',
  epgLoginPopupContent2: 'Do you want to login now?',
  // Live Player Screen
  channel: 'channel',
  fullGuide: 'full guide',
  upNext: 'up next',
  // MyContent Screen
  myContentEmptyText:
    'No favourite content, neither bookmarks. Please add content to your favourites',
  // Player Screen
  seasonWithNumber: 'Season {{seasonNumber}}',
  episodeWithNumber: 'Episode {{episodeNumber}}',
  trailer: 'Trailer',
  episode: 'Episode',
  movie: 'Movie',
  errorPopupTitle: 'Error',
  errorPopupContent: 'Error {{code}}: {{message}}',
  // Detail Screen
  moreLikeThis: 'More Like This',
  playTrailer: 'PLAY TRAILER',
  play: 'PLAY',
  playEpisode: 'WATCH S{{seasonNumber}} E{{episodeNumber}} EPISODE',
  subscribe: 'SUBSCRIBE',
  addFavourite: 'ADD FAVOURITE',
  removeFavourite: 'REMOVE FAVOURITE',
  loadErrorTitle: 'Error Loading Data',
  loadErrorMessage: 'Please review your connection and try again later',
  resumePopupTitle: 'Bookmark found',
  resumePopupContent: 'Would you like to resume from {{bookmark}}?',
  resume: 'Resume',
  startOver: 'Start Over',
  remindMe: 'Remind me',
  // Show Detail Screen
  thereAreNoSeasonsAvailableTitle: 'There are no seasons available',
  thereAreNoSeasonsAvailableDescription:
    'Sorry! There are no seasons available for this TV Show',
  thereAreNoEpisodesAvailableTitle: 'There are no episodes available',
  thereAreNoEpisodesAvailableDescription:
    'Sorry! There are no episodes available for this TV Show."',
  // ConTWatching Screen
  continueWatching: {
    seasonInfo: 'Season {{seasonNumber}} | Episode {{episodeNumber}}'
  },
  // Category Screen
  category: {
    viewAll: 'View All'
  },
  // list Screen
  list: {
    clear: 'Clear',
    disable: 'Disable'
  },
  // Profile Screen
  moreDetails: 'More details',
  // About Screen
  aboutActionButton: 'Go to home page',
  aboutDescription: `Below is information listed from the application's NPM package.`,
  aboutTitle: 'About',
  // ViewDoesNotExistInTheApp Screen
  viewDoesNotExistInTheAppTitle: 'View does not exist in Elevate',
  viewDoesNotExistInTheAppDescription: `It's not possible to map this route from Accedo Control, because this view still doesn't exist in Elevate.`
};
