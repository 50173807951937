import React from 'react';
import styles from './avatar.scss';

interface AvatarProps {
  src?: string;
  isFocused: boolean;
  name?: string;
}

const getInitials = (name = '') => {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials.toUpperCase();
};

const Avatar = ({ src, isFocused, name = 'default' }: AvatarProps) => (
  <div
    className={`${styles.avatar} ${isFocused ? styles.focused : ''} ${
      !src ? styles.name : ''
    }`}
  >
    {src ? <img src={src} alt="" /> : getInitials(name)}
  </div>
);

export default Avatar;
