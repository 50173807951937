import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useRef
} from 'react';
import PropTypes from 'prop-types';

import { focusManager } from '@accedo/vdkweb-navigation';

import { AuthContext } from '#/context/AuthContext';
import PointerContextProvider from '#/context/PointerContext';
import { PageProvider } from '#/context/PageContext';
import SplashScreen from '#/components/SplashScreen/SplashScreen';
import Popup from '#/components/Popup/Popup';
import { getTranslation } from '#/services/i18n';
import useAppConfig from '#/hooks/useAppConfig';
import useNetworkStatus from '#/hooks/useNetworkStatus';
import useApiError from '#/hooks/useApiError';
import useI18n from '#/hooks/useI18n';
import useExtraStyleClasses from '#/hooks/useExtraStyleClasses';

import defaultLocalTheme from '#/theme/defaultTheme.json';
import setCssVariables from '#/utils/setCssVariables';

import styles from './tvApp.scss';

const MAINTENANCE = 'maintenance';

// The entry point of your application. This is where
// you can control rendering based on received application
// status or configurations.
const TVAppContent = ({ platform, children }) => {
  const context = useContext(AuthContext);
  const [state, setState] = useState({
    user: undefined
  });
  const { config } = useAppConfig();
  const { extraClasses } = useExtraStyleClasses();
  const { currentLocale = {} } = useI18n();
  const { code: localeCode } = currentLocale;

  const { networkStatus } = useNetworkStatus();
  const { addError, removeError } = useApiError();

  /**
   * to avoid style problems
   */
  useEffect(() => {
    setCssVariables(config.theme || defaultLocalTheme);
  }, [config]);

  const prevFocusForNetworkStatus = useRef();

  const onStartUp = useCallback(async () => {
    // TODO: dev here your custom logic for the startup
    return context.checkAuthStatus();
  }, [context]);

  useEffect(() => {
    if (networkStatus === false) {
      prevFocusForNetworkStatus.current = focusManager.getCurrentFocus();
      addError();
    } else {
      removeError();
      if (prevFocusForNetworkStatus && prevFocusForNetworkStatus.current) {
        focusManager.changeFocus(prevFocusForNetworkStatus.current);
      }
    }
  }, [networkStatus, addError, removeError]);

  useEffect(() => {
    const getUser = async () => {
      const user = await onStartUp();
      setState({ user });
    };
    if (!config.status && state.user === undefined) {
      getUser();
    }
  }, [config.status, state.user, onStartUp]);

  useEffect(() => {
    if (config.status === 'Active' && state.user !== undefined) {
      focusManager.setPersistTrail(true);
    }
  }, [config, state.user]);

  // Remove the splash screen
  useEffect(() => {
    if (config.status === 'Active' && Object.keys(config).length > 0) {
      const splash = document.getElementById('splash');
      splash?.remove?.();
    }
  }, [config, localeCode]);

  if (config?.errorLoading) {
    focusManager.changeFocus('ERROR_POPUP');
    return (
      <Popup
        nav={{
          id: 'ERROR_POPUP'
        }}
        isOpen
        title={getTranslation('configErrorLoadingTitle')}
        content={<span>{getTranslation('configErrorLoadingContent')}</span>}
        onAccept={() => {
          window.location.reload();
        }}
        theme={styles}
        acceptText={getTranslation('okButton')}
      />
    );
  }

  // If we haven't yet received an application status, we'll
  // simply return an empty div.
  if (!localeCode || state.user === undefined) {
    return <div className={styles.rootTransparent} />;
  }

  // We require the configuration to have been
  // provided before we render the actual app content.
  // simply return an empty div.
  if (!Object.keys(config).length) {
    return <div className={styles.rootTransparent} />;
  }

  // If we're in maintenance mode, we'll display
  // the given message or fall back to a default one.
  if (config.status === MAINTENANCE) {
    return <SplashScreen status={SplashScreen.status.MAINTENANCE} />;
  }

  // TODO: uncomment if you want to make the login blocking the app
  // if (this.context.isCheckingAuth) {
  //   return (
  //     <SplashScreen status={SplashScreen.status.NO_CONDATA_LOADINGFIG} />
  //   );
  // }

  // At this point we have both an active status and
  // a configuration object. Let's render the app!
  const extraClassesString = extraClasses
    ?.map(extraClass => styles[extraClass])
    ?.join(' ');
  return (
    <PageProvider>
      <PointerContextProvider>
        <div className={`${styles.root} ${platform} ${extraClassesString}`}>
          {children}
        </div>
      </PointerContextProvider>
    </PageProvider>
  );
};

TVAppContent.propTypes = {
  platform: PropTypes.string,
  children: PropTypes.node
};

export default TVAppContent;
