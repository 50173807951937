import ovp from '@accedo/vdkweb-ovp-client-accedo';
// import vdkFetch from '@accedo/vdkweb-fetch';
import dayjs from 'dayjs';

import {
  getProtocolRelativePathUrl,
  getQueryStringParameters
} from '#/utils/url';
import config from '#/config';

const errorHandler = error => console.error(error);

const channelInfoCache = {};
const CACHE_SIZE = 100 * 60 * 1000; // 5 mins

const { accedoOvpUrl } = config.app;

/**
 * @module providers/ovp
 * @description
 * Provider OVP implementation for Accedo OVP
 */

/**
 * Override of the default function to avoid CORS issues.
 * Invokes the REST service using the supplied settings and parameters.
 * @param {String} path The base URL to invoke.
 * @param {String} httpMethod The HTTP method to use.
 * @param {Object.<String, String>} pathParams A map of path parameters and their values.
 * @param {Object.<String, Object>} queryParams A map of query parameters and their values.
 * @param {Object.<String, Object>} headerParams A map of header parameters and their values.
 * @param {Object.<String, Object>} formParams A map of form parameters and their values.
 * @param {Object} bodyParam The value to pass as the request body.
 * @param {Array.<String>} authNames An array of authentication type names.
 * @param {Array.<String>} contentTypes An array of request MIME types.
 * @param {Array.<String>} accepts An array of acceptable response MIME types.
 * @param {(String|Array|Object|Function)} returnType The required type to return; can be a string for simple types or the
 * constructor for a complex type.
 * @returns {Promise} A {@link https://www.promisejs.org/|Promise} object.
 */
ovp.ApiClient.prototype.call = function call(
  path,
  httpMethod,
  pathParams,
  queryParams,
  headerParams,
  formParams,
  bodyParam,
  authNames,
  contentTypes,
  accepts
) {
  const url = ovp.ApiClient.prototype.buildUrl(
    path,
    pathParams,
    ovp.ApiClient.prototype.normalizeParams(queryParams)
  );

  const headers = {
    ...{},
    ...ovp.ApiClient.defaultHeaders,
    ...ovp.ApiClient.prototype.normalizeParams(headerParams)
  };
  const contentType = ovp.ApiClient.prototype.jsonPreferredMime(contentTypes);
  headers['Content-Type'] = contentType;
  const acceptType = ovp.ApiClient.prototype.jsonPreferredMime(accepts);
  if (acceptType) {
    headers.Accept = acceptType;
  }

  if (contentType === 'multipart/form-data') {
    throw new Error('Not implemented - contentType not supported');
  }

  let body;
  if (httpMethod !== 'GET' && httpMethod !== 'HEAD' && bodyParam) {
    body = JSON.stringify(bodyParam);
  }

  const options = {
    method: httpMethod,
    headers
  };

  if (body) {
    options.body = body;
  }

  return fetch(url).then(response => {
    // Fetch API only rejects a promise when a network error is encountered
    // so an error response needs to be thrown forward
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Checking_that_the_fetch_was_successful
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
};

ovp.ApiClient.prototype.getBaseUrl = () => {
  // We consider the case for TV here.  For static build, proxy server will not
  // be available unless using a custom server.  And as most TV do not have
  // cross domain restrictions, we will get the exact OVP API url.
  return accedoOvpUrl;
};

const movieDataParser = entry => ({
  ...entry,
  displayText: entry.title
});

const getMovieData = async ({
  category = '',
  sortBy = '',
  pageSize = 15
} = {}) => {
  const apiInstance = new ovp.MovieApi();

  const opts = {
    pageSize,
    pageNumber: 1,
    sortBy
  };

  try {
    const data = await (category
      ? apiInstance.getMoviesByCategory(category, opts)
      : apiInstance.getAllMovies(opts));

    return data.entries.map(entry => {
      return {
        ...entry,
        displayText: entry.title
      };
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getMovieById = id => {
  if (!id) {
    return Promise.resolve(null);
  }
  const apiInstance = new ovp.MovieApi();

  return apiInstance.getMovieById(id).catch(errorHandler);
};

const getMoviesByCategory = async (category, sortBy) => {
  // Due to category OVP limitation we can't paginate so we request more than default pageSize
  return category ? getMovieData({ category, sortBy, pageSize: 50 }) : null;
};

const getMovieDataById = id => getMovieById(id).then(movieDataParser);

const getTvShowData = async ({ category = '', pageSize = 15 } = {}) => {
  const apiInstance = new ovp.TVShowApi();

  const opts = {
    pageSize,
    pageNumber: 1
  };

  try {
    const data = await (category
      ? apiInstance.getTvShowsByCategory(category, opts)
      : apiInstance.getAllTvShows(opts));

    return data.entries.map(entry => {
      return {
        ...entry,
        displayText: entry.title,
        type: 'tvshow'
      };
    });
  } catch (error) {
    console.error(error);
  }
};

const getTvShows = async (options = {}) => {
  const opts = {
    pageSize: 50,
    ...options
  };
  const apiInstance = new ovp.TVShowApi();
  return apiInstance.getAllTvShows(opts);
};

const getMovies = async (options = {}) => {
  const opts = {
    pageSize: 50,
    ...options
  };
  const apiInstance = new ovp.MovieApi();
  return apiInstance.getAllMovies(opts);
};

/**
 * Search for Movies
 * @param {String} keyword Keyword to search for Movies
 * @param {Number} amount Amount of items to return
 * @return {Array<Object>} Results
 */
const searchMovies = (keyword, amount) => {
  if (!keyword) {
    return Promise.resolve(null);
  }
  const apiInstance = new ovp.SearchApi();
  return apiInstance
    .searchMovies(keyword, { sortBy: 'title', pageSize: amount })
    .catch(errorHandler);
};

/**
 * Search for Shows
 * @param {String} keyword Keyword to search for Shows
 * @param {Number} amount Amount of items to return
 * @return {Array<Object>} Results
 */
const searchShows = (keyword, amount) => {
  if (!keyword) {
    return Promise.resolve(null);
  }
  const options = {
    sortBy: 'title',
    pageSize: amount
  };

  const params = new URLSearchParams();
  Object.keys(options).forEach(key => params.append(key, options[key]));

  return fetch(`${accedoOvpUrl}/search/tvshow/${keyword}?${params.toString()}`)
    .then(reponse => reponse.json())
    .catch(errorHandler);
};

/**
 * Get a TV Show
 * @todo Review the TV Show API since it doesn't return
 * all the information provided by APIs. https://github.com/Accedo-Global-Solutions/vdkweb-ovp-client-accedo/tree/296088a7709a953c1a7aea345c38b316332e339c/docs/TVShowApi.md#getTvShowById
 * @param {String} id TV Show ID
 * @returns {Object} TV Show
 */
const getTvShowById = id => {
  if (!id) {
    return Promise.resolve(null);
  }
  return fetch(`${accedoOvpUrl}/tvshow/${id}`)
    .then(reponse => reponse.json())
    .catch(errorHandler);
};

/**
 * Get episodes from a season
 * @param {String} id Season ID
 * @returns {Array<Object>} Episodes
 */
const getTvSeasonEpisodesById = id => {
  if (!id) {
    return Promise.resolve(null);
  }
  const apiInstance = new ovp.EpisodeApi();

  return apiInstance.getTvSeasonEpisodes(id).catch(errorHandler);
};

/**
 * Get episodes from a tv show
 * @param {String} id TV Show ID
 * @returns {Array<Object>} Episodes
 */
const getTvShowEpisodesById = id => {
  if (!id) {
    return Promise.resolve(null);
  }
  const apiInstance = new ovp.EpisodeApi();

  return apiInstance.getTvShowEpisodes(id).catch(errorHandler);
};

/**
 * Get seasons from a tv show
 * @param {String} id TV Show ID
 * @returns {Array<Object>} Seasons
 */
const getTvShowSeasonsById = id => {
  if (!id) {
    return Promise.resolve(null);
  }
  const apiInstance = new ovp.TVSeasonApi();

  return apiInstance
    .getTvShowSeasons(id, { sortBy: 'tvSeasonNumber' })
    .catch(errorHandler);
};

/**
 * Get an episode
 * @param {String} id Episode ID
 * @returns {Object} Episode
 */
const getEpisodeById = id => {
  if (!id) {
    return Promise.resolve(null);
  }
  const apiInstance = new ovp.EpisodeApi();

  return apiInstance.getEpisodeById(id).catch(errorHandler);
};

const getTvShowsByCategory = async category => {
  // Due to category OVP limitation we can't paginate so we request more than default pageSize
  return category ? getTvShowData({ category, pageSize: 50 }) : null;
};

const getMovieCategories = async () => {
  const apiInstance = new ovp.CategoryApi();

  const opts = {
    pageSize: 10,
    pageNumber: 1
  };

  try {
    const movieCategoryId = 'movies';
    const data = await apiInstance.getCategoryById(movieCategoryId, opts);

    return data.categories
      .map(entry => {
        return {
          ...entry,
          displayText: entry.title
        };
      })
      .slice(0, opts.pageSize);
  } catch (error) {
    console.error(error);
  }
};

const signIn = credentials => {
  return new ovp.AuthApi().authenticate(
    credentials.email,
    credentials.password
  );
};

const signOut = token => {
  return new ovp.AuthApi().invalidateToken(token);
};

const validateToken = (token, userId) => {
  return new ovp.AuthApi().validateToken(token, userId);
};

let channelDataCache;

const getChannelData = () => {
  if (channelDataCache) {
    return Promise.resolve(channelDataCache);
  }

  const apiInstance = new ovp.ChannelApi();

  return apiInstance
    .getAllChannels()
    .then(channelData => {
      channelDataCache = channelData;
      return channelData;
    })
    .catch(error => {
      console.error(error);
    });
};

const getCategories = async () => {
  const apiInstance = new ovp.CategoryApi();

  const opts = {
    pageSize: 15,
    pageNumber: 1
  };

  try {
    const data = await apiInstance.getCategories(opts);

    return data.categories.map(entry => {
      return {
        ...entry,
        displayText: entry.title
      };
    });
  } catch (error) {
    console.error(error);
  }
};

const getTvListings = async ({ startTime, endTime, count = 4, offset = 0 }) => {
  const apiInstance = new ovp.TVListingApi();

  const params = {
    pageSize: count,
    pageNumber: 1 + Math.max(Math.round(offset / count), 0)
  };

  const channels = await getChannelData();

  const data = await apiInstance.getTvListing(startTime, endTime, params);
  return {
    ...data,
    entries: data.entries.map(entry => {
      const channelData = channels.entries.find(
        channel => channel.id === entry.channelId
      );
      if (channelData && channelData.images && channelData.images.length) {
        channelData.images.forEach(image => {
          image.url = getProtocolRelativePathUrl(image.url);
        });
      }

      return {
        ...(channelData || {}),
        ...entry,
        programs: entry.programs
          .filter(
            p =>
              dayjs(p.startTime).day() === dayjs(startTime).day() ||
              (p.endTime <= endTime && p.startTime < endTime)
          )
          .map(p => {
            return {
              ...p,
              title: p.title,
              videoUrl:
                '//d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4'
            };
          })
      };
    })
  };
};

/**
 * Get TVListing from a channel, doing a general tvlisting request and caching the value for all
 * the channels and then returning only the channel provided by param.
 * Challenge: define time windows to use the cached values and when to refresh the data
 *
 * @param {*} channel Channel Identifier
 * @param {*} startTime Time to get content from (small window)
 *
 * @returns {Promise<any>} tvlistings
 */
const getCachedTvListings = async ({ channel, startTime }) => {
  if (
    !channelInfoCache?.promise ||
    startTime > channelInfoCache?.maxStartTime
  ) {
    const endTime = startTime + CACHE_SIZE;
    channelInfoCache.promise = getTvListings({
      startTime,
      endTime,
      channel,
      count: 100
    });
    channelInfoCache.maxStartTime = endTime;
  }
  const tvlistingsPromise = channelInfoCache?.promise;
  const tvlistings = await tvlistingsPromise.then(response => response);
  const filteredListings = tvlistings.entries.filter(
    entry => entry.channelId === channel
  );
  return filteredListings?.[0] || {};
};

const getChannelTvListings = async ({ channel, startTime }) => {
  const tvListings = await getCachedTvListings({ channel, startTime });
  return (
    tvListings.programs?.find(program => {
      const currentTime = Date.now();
      return currentTime > program.startTime && currentTime <= program.endTime;
    }) || {}
  );
};

const getItemsByQuery = async ({ query, itemsPerPage, pageNumber, sortBy }) => {
  if (!query) {
    return null;
  }

  const pageSize = itemsPerPage;

  const optionsRequest = {
    sortBy,
    pageSize,
    pageNumber
  };

  const queryParams = getQueryStringParameters(query);

  const searchParams = new URLSearchParams();
  Object.keys(optionsRequest).forEach(key => {
    if (!queryParams || !queryParams[key]) {
      // query could have params already, we do not want duplicate them
      optionsRequest[key] && searchParams.append(key, optionsRequest[key]);
    }
  });

  const hasParams = searchParams.toString().length > 0;
  const fetchUrl = hasParams
    ? `${accedoOvpUrl}${query}?${searchParams.toString()}`
    : `${accedoOvpUrl}${query}`;

  try {
    let items = [];
    let data = {};
    const response = await fetch(fetchUrl);
    data = await response.json();
    items = data.entries;

    return { items, total: data.totalCount };
  } catch (error) {
    errorHandler(error);
  }
};

export default {
  getCategories,
  getChannelData,
  getEpisodeById,
  getMovieById,
  getMovieCategories,
  getMovieData,
  getMovieDataById,
  getMovies,
  getMoviesByCategory,
  getTvListings,
  getChannelTvListings,
  getTvShows,
  getTvSeasonEpisodesById,
  getTvShowData,
  getTvShowById,
  getTvShowEpisodesById,
  getTvShowSeasonsById,
  getTvShowsByCategory,
  searchMovies,
  searchShows,
  signIn,
  signOut,
  validateToken,
  getItemsByQuery
};
