const getProtocolRelativePathUrl = (src = '') => {
  if (typeof src !== 'string') {
    return;
  }
  const relativePathSeparator = '//';
  const pathSeparatorIndex = src.indexOf(relativePathSeparator);

  if (pathSeparatorIndex > -1) {
    return src.slice(pathSeparatorIndex);
  }
  return src;
};

const getQueryStringParameters = url => {
  if (url.split('?').length > 1) {
    const query = url.split('?')[1];

    return query.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
  }
};

export { getProtocolRelativePathUrl, getQueryStringParameters };
