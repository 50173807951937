/**
 * @module providers/bookmark
 *
 * @description
 * This providers handles bookmark items. It implements XDK Core
 * storage class to use the proper local storage for each device.
 *
 * @see https://github.com/Accedo-Global-Solutions/xdk/tree/main/packages/xdk-core/src/interfaces/Storage.js
 *
 * Feel free to fill these methods with app's logic having in mind
 * to preserve names, params and return types since this service
 * can be used by hooks, high order components, or other React API.
 *
 * Also remove the helper methods to make it clean if it doesn't
 * make sense in your code.
 */

import { device } from '@accedo/xdk-core';
import { mapToString, stringToMap } from '#/utils/dataConverterHelper';

import { buildStorageKey } from '#/utils/storageManager';

/** @type {String} */
const BOOKMARK_KEY = 'vdkctv-bookmark';

/** @type {String} */
const SHOWS_LATEST_EPISODES_KEY = 'vdkctv-bookmark-latest-episodes';

/** @type {String} */
const STORAGE_STRATEGY = 'local';

/**
 * Helper method to get string values from storage
 * @param {string} profileId Unique favoriteKey. Identify the user in the database.
 * @returns {Promise<Map>} Promise with Bookmark storage items
 */
export const getBookmark = async profileId => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(STORAGE_STRATEGY);
  return stringToMap(storage.get(buildStorageKey(BOOKMARK_KEY, profileId)));
};

/**
 * Helper method to set a new string into storage
 * @param {string} profileId Unique favoriteKey. Identify the user in the database.
 * @param {Map} value Bookmark map
 * @returns {Promise<void>} Void
 */
export const setBookmark = async (profileId, value) => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(STORAGE_STRATEGY);
  storage.set(buildStorageKey(BOOKMARK_KEY, profileId), mapToString(value));
};

/**
 * Helper method to clear the Bookmark storage
 * @param {string} profileId Unique favoriteKey. Identify the user in the database.
 * @returns {Promise<void>} Void
 */
export const clearBookmark = async profileId => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(STORAGE_STRATEGY);
  storage.unset(buildStorageKey(BOOKMARK_KEY, profileId));
};

/**
 * Helper method to get ShowLatestEpisode values from storage
 * @param {string} profileId Unique favoriteKey. Identify the user in the database.
 * @returns {Promise<Map>} Promise with ShowLatestEpisode storage items
 */
export const getShowLatestEpisode = async profileId => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(STORAGE_STRATEGY);
  return stringToMap(
    storage.get(buildStorageKey(SHOWS_LATEST_EPISODES_KEY, profileId))
  );
};

/**
 * Helper method to set a new ShowLatestEpisode into storage
 * @param {string} profileId Unique favoriteKey. Identify the user in the database.
 * @param {Map} value ShowLatestEpisode map
 * @returns {Promise<void>} Void
 */
export const setShowLatestEpisode = async (profileId, value) => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(STORAGE_STRATEGY);
  storage.set(
    buildStorageKey(SHOWS_LATEST_EPISODES_KEY, profileId),
    mapToString(value)
  );
};

/**
 * Helper method to clear the ShowLatestEpisode storage
 * @param {string} profileId Unique favoriteKey. Identify the user in the database.
 * @returns {Promise<void>} Void
 */
export const clearShowLatestEpisode = async profileId => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage(STORAGE_STRATEGY);
  storage.unset(buildStorageKey(SHOWS_LATEST_EPISODES_KEY, profileId));
};
