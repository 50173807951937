import { ITEM_TYPES, IMAGE_TYPE } from '#/config/constants';

/** Dimensions in px */
export const ITEM_SIZE = {
  [ITEM_TYPES.Wide]: { width: 570, height: 320.6 },
  [ITEM_TYPES.Portrait]: { width: 362, height: 543 },
  [ITEM_TYPES.GridPortrait]: { width: 330, height: 496 },
  [ITEM_TYPES.GridLandscape]: { width: 590, height: 404 },
  [ITEM_TYPES.FeaturedCarouselPortrait]: { width: 330, height: 496 },
  [ITEM_TYPES.Landscape]: { width: 565, height: 318 },
  [ITEM_TYPES.Square]: { width: 200, height: 200 },
  [ITEM_TYPES.Bookmark]: { width: 570, height: 320.6 },
  [ITEM_TYPES.Categories]: { width: 350, height: 120 },
  [ITEM_TYPES.SeasonEpisode]: { width: 570, height: 400 },
  [ITEM_TYPES.SearchMovie]: { width: 250, height: 375 },
  [ITEM_TYPES.SearchEpisode]: { width: 444, height: 250 },
  [ITEM_TYPES.HeroBanner]: { width: 1920, height: 864 }
};

export const CUSTOM_IMAGE_FORMAT = {
  [ITEM_TYPES.Wide]: ITEM_TYPES.Wide,
  [ITEM_TYPES.GridLandscape]: ITEM_TYPES.GridLandscape,
  [ITEM_TYPES.SearchMovie]: IMAGE_TYPE.POSTER,
  [ITEM_TYPES.SearchEpisode]: IMAGE_TYPE.BACKDROP,
  [ITEM_TYPES.Square]: ITEM_TYPES.Square
};
