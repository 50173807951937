import { useState, useEffect, useCallback, useMemo } from 'react';
import { bookmarkService } from '#/services/userData';
import Bookmark from '#/models/Bookmark/bookmark';

/**
 * @param params {Object} React ref to avoid re-definitions of callbacks
 * @param params.dataRef {Object} React ref to avoid re-definitions of callbacks
 * @param params.isAuthenticated {boolean} React ref to avoid re-definitions of callbacks
 * @param params.userName {string} React ref to avoid re-definitions of callbacks
 * @returns {Object} userBookmarksObject
 * @returns {Array} userBookmarksObject.userBookmarks
 * @returns {Function} userBookmarksObject.getUserBookmarks
 * @returns {Function} userBookmarksObject.setBookmark
 * @returns {Function} userBookmarksObject.removeBookmark
 * @returns {Function} userBookmarksObject.removeLatestEpisode
 * @returns {Function} userBookmarksObject.getBookmarkById
 * @returns {Function} userBookmarksObject.clearBookmark
 * @returns {Function} userBookmarksObject.isBookmark
 */

const useBookmarks = ({ dataRef, isAuthenticated, userName }) => {
  const {
    getContinueWatchingItems,
    getBookmark,
    addBookmark,
    removeBookmark,
    removeLatestEpisode,
    clearBookmark,
    isBookmark
  } = useMemo(() => bookmarkService(userName), [userName]);

  const [userBookmarks, setUserBookmarks] = useState();

  const getUserBookmarks = useCallback(async () => {
    let bookmarks = [];
    if (isAuthenticated) {
      bookmarks = await getContinueWatchingItems();
    }

    return bookmarks;
  }, [isAuthenticated, getContinueWatchingItems]);

  const setBookmark = useCallback(async () => {
    if (!isAuthenticated || !dataRef) {
      return [];
    }
    const { progress, duration, asset } = dataRef.current;

    const progressPercentage = (progress * 100) / duration;

    const bookmark = Bookmark({
      ...asset,
      progress: progressPercentage,
      resumeTime: progress
    });
    addBookmark(bookmark);
  }, [dataRef, isAuthenticated, addBookmark]);

  const getBookmarkById = useCallback(
    ({ id: assetId }) => {
      if (!isAuthenticated) {
        return null;
      }
      return getBookmark(assetId);
    },
    [isAuthenticated, getBookmark]
  );

  useEffect(() => {
    const loadBookmarks = async () => {
      setUserBookmarks(await getUserBookmarks());
    };
    if (!userBookmarks) {
      loadBookmarks();
    }
  }, [userBookmarks, getUserBookmarks]);

  return {
    userBookmarks,
    getUserBookmarks,
    setBookmark,
    removeBookmark,
    removeLatestEpisode,
    getBookmarkById,
    clearBookmark,
    isBookmark
  };
};

export default useBookmarks;
