import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { formatProgramTime } from '@accedo/vdkweb-epg';
import { withFocus } from '@accedo/vdkweb-navigation';
import { RoundButton } from '#/components/RoundButton/RoundButton';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import useItemStyle from '#/hooks/useItemStyle';
import { getProgramData } from '#/services/ovp/live';
import { getTranslation } from '#/services/i18n';

import channelBannerItemTheme from './channelBannerItem.scss';

const ChannelBannerItem = ({
  onClick,
  theme = channelBannerItemTheme,
  nav = {},
  data,
  width,
  height,
  buttonText
}) => {
  const [program, setProgramData] = useState({});
  const [progress, setProgress] = useState(0);
  const backgroundClassName = `${theme.background} ${
    nav.isFocused ? theme.backgroundFocused : ''
  }`;
  const updateProgress = useCallback(() => {
    const currentTime = Date.now();
    const diff = program.endTime - program.startTime;
    const diffToNow = currentTime - program.startTime;
    setProgress((diffToNow * 100) / diff);
  }, [program.startTime, program.endTime]);

  useEffect(() => {
    const updateData = async () => {
      const currentTime = Date.now();
      const programData = await getProgramData(data.id, currentTime);
      setProgramData(programData);
      updateProgress();
    };
    updateData();

    const interval = setInterval(() => {
      updateData();
    }, 10 * 1000); // TODO: update with value from Control or default

    return () => {
      return clearInterval(interval);
    };
  }, [data.id, updateProgress]);

  const { itemStyle } = useItemStyle({
    program,
    type: 'hero'
  });
  return (
    <div style={{ width, height }}>
      <div
        className={backgroundClassName}
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
      >
        <ImageWithPreload src={itemStyle.imageUrl} width="100%" height="100%" />
        <div className={theme.contentContainer}>
          <div className={theme.content}>
            <div className={theme.channelContainer}>
              <span className={theme.channelLogo}>
                <img src={data.logo} alt="Channel Logo" />
              </span>
              <span className={theme.channelTitle}>
                {data.displayText || data.title}
              </span>
            </div>
            <div className={theme.programContainer}>
              <div className={theme.programTitle}>{program?.title}</div>
              <div className={theme.programDetail}>
                {formatProgramTime(
                  dayjs(program?.startTime),
                  dayjs(program?.endTime),
                  'HH:mm'
                )}
              </div>
            </div>

            <RoundButton
              className={theme.button}
              onClick={onClick}
              displayText={buttonText || getTranslation('watchNowButton')}
              nav={nav}
            />
            <div className={theme.progress}>
              <span style={{ width: `${progress}%` }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChannelBannerItem.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  nav: PropTypes.object,
  onClick: PropTypes.func,
  theme: PropTypes.object
};

export default withFocus(ChannelBannerItem);
