import { mapToArray } from '#/utils/dataConverterHelper';
import {
  getBookmark as getBookmarkFromProvider,
  setBookmark,
  clearBookmark as clearBookmarkFromProvider,
  getShowLatestEpisode,
  setShowLatestEpisode,
  clearShowLatestEpisode
} from '#/providers/bookmark';
import { getSeasons, getEpisodes, getNextEpisode } from '#/services/ovp';
import { getConfiguration } from '#/services/cms';
import { CONTAINER_ITEM_TYPES } from '#/config/constants';
import Bookmark from '#/models/Bookmark/bookmark';

const bookmarkService = profileId => {
  const addLatestWatchedEpisode = async bookmarkItem => {
    const latestEpisodes = (await getShowLatestEpisode(profileId)) || new Map();
    // we need to add some flag to tell the detail page the content is bookmarked

    latestEpisodes.set(
      bookmarkItem.showId,
      JSON.stringify({ ...bookmarkItem })
    );
    setShowLatestEpisode(profileId, latestEpisodes);
    return Promise.resolve(true);
  };

  const removeLatestEpisode = async key => {
    const latestEpisodes = await getShowLatestEpisode(profileId);
    latestEpisodes.has(key) &&
      latestEpisodes.delete(key) &&
      setShowLatestEpisode(profileId, latestEpisodes);
    return Promise.resolve(true);
  };

  /**
   * Get the last episode watched fully or the latest with progress.
   * @param {String} showId - The id for the show.
   * @returns {Bookmark | undefined} - The latest episode watched.
   */
  const getLatestWatchedEpisodeForShow = async showId => {
    const latestEpisodes = await getShowLatestEpisode(profileId);

    const bookmark = latestEpisodes.get(showId);

    if (!bookmark) {
      return Promise.resolve(null);
    }

    return Promise.resolve(Bookmark(bookmark));
  };

  /**
   * Add an entry to the Bookmark store.
   * @example
   * // Assuming refreshBookmark is your logic
   * addBookmark('star-wars', { title: 'Star Wars' }).then(added => refreshBookmark(added))
   * @param {Bookmark} bookmarkItem The item to be stored
   * @returns {Promise<boolean>} Promise with a flag that indicates
   * the success for adding an entry to the Bookmark storage
   */
  const addBookmark = async bookmarkItem => {
    const bookmarks = (await getBookmarkFromProvider(profileId)) || new Map();
    // we need to add some flag to tell the detail page the content is bookmarked

    bookmarks.set(bookmarkItem.id, JSON.stringify({ ...bookmarkItem }));
    setBookmark(profileId, bookmarks);

    // The following logic is done for saving the most advanced episode
    // of a show as the latest episode for the user. Therefore this episode
    // will be returned as the default playable to the show detail page.
    if (bookmarkItem.assetType === CONTAINER_ITEM_TYPES.Episode) {
      // we store it as the latest watched episode
      const latestShowEpisode = await getLatestWatchedEpisodeForShow(
        bookmarkItem.showId
      );
      if (
        !latestShowEpisode ||
        bookmarkItem.seasonNumber > latestShowEpisode.seasonNumber ||
        (bookmarkItem.seasonNumber === latestShowEpisode.seasonNumber &&
          bookmarkItem.episodeNumber > latestShowEpisode.episodeNumber) ||
        (bookmarkItem.seasonNumber === latestShowEpisode.seasonNumber &&
          bookmarkItem.episodeNumber === latestShowEpisode.episodeNumber)
      ) {
        // the new asset is the most advanced for the show
        addLatestWatchedEpisode(bookmarkItem);
      }
    }

    return Promise.resolve(true);
  };

  /**
   * Remove an entry from the Bookmark store.
   * @example
   * // Assuming refreshBookmark is your logic
   * removeBookmark('star-wars').then(removed => refreshBookmark(removed))
   * @param {String|Number} key Bookmark item to be removed
   * @returns {Promise<boolean>} Promise with a flag that indicates
   * the success for removing an entry to the Bookmark storage
   */
  const removeBookmark = async key => {
    const bookmarks = await getBookmarkFromProvider(profileId);
    bookmarks.has(key) &&
      bookmarks.delete(key) &&
      setBookmark(profileId, bookmarks);
    return Promise.resolve(true);
  };

  /**
   * Clear the Bookmark store.
   * @example
   * // Assuming refreshBookmark is your logic
   * clearBookmark().then(clean => refreshBookmark(clean))
   * @returns {Promise<boolean>} Promise with a flag that indicates
   * the success for cleaning the Bookmark storage
   */
  const clearBookmark = async () => {
    await clearBookmarkFromProvider(profileId);
    await clearShowLatestEpisode(profileId);
    return Promise.resolve(true);
  };

  /**
   * Get a Bookmark item from the store.
   * @example
   * // Assuming refreshBookmark is your logic
   * getBookmark(key).then(bookmark => refreshBookmark(bookmark))
   * @param {String} key The specific entry key
   * @returns {Promise<Bookmark|null>} Promise with all Bookmark storage items
   */
  const getBookmark = async key => {
    const bookmarks = await getBookmarkFromProvider(profileId);
    const bookmark = bookmarks.get(key);

    if (!bookmark) {
      return Promise.resolve(null);
    }

    return Promise.resolve(Bookmark(bookmark));
  };

  /**
   * Get Bookmark items from the store.
   * @example
   * // Assuming refreshBookmark is your logic
   * getBookmark().then(bookmark => refreshBookmark(bookmark))
   * @returns {Promise<Array>} Promise with all Bookmark storage items
   */
  const getBookmarks = async () =>
    Promise.resolve(mapToArray(await getBookmarkFromProvider(profileId)));

  const getShowsBookmarks = async () =>
    Promise.resolve(mapToArray(await getShowLatestEpisode(profileId)));

  const getBookmarksForShow = async showId => {
    const bookmarks = (await getBookmarks()) || [];
    const showEpisodes = bookmarks.filter(
      asset =>
        asset.assetType === CONTAINER_ITEM_TYPES.Episode &&
        asset.showId === showId
    );
    return showEpisodes;
  };

  /**
   * Gets the onprogress movies and the latest episode for each shwho
   * @returns {Array<Bookmark>} - The movies and episode items
   */
  const getContinueWatchingItems = async () => {
    const bookmarks = (await getBookmarks()) || [];
    const episodes = (await getShowsBookmarks()) || [];
    const movies =
      bookmarks.filter(
        asset => asset.assetType === CONTAINER_ITEM_TYPES.Movie
      ) || [];

    const moviesBookmarks = movies.map(movie => Bookmark(movie));
    const episodesBookmarks = episodes.map(episode => Bookmark(episode));

    return [...moviesBookmarks, ...episodesBookmarks];
  };

  /**
   * Check if some entry is stored in Bookmark store.
   * @example
   * // Assuming setBookmark is your logic
   * isBookmark('star-wars').then(bookmarked => setBookmark(bookmarked))
   * @param {String|Number} key Bookmark item to be removed
   * @returns {Promise<boolean>} Promise with a flag that indicates if a
   * specific key is stored on Bookmark storage
   */
  const isBookmark = async key => {
    const bookmarks = await getBookmarkFromProvider(profileId);
    return Promise.resolve(bookmarks.has(key));
  };

  /**
   * Return the episode to start/continue watching for an specified show.
   * @param {String} showId - The id for the show.
   * @returns {Bookmark} - The Episode to watch.
   */
  const getNextEpisodeToPlayForShow = async showId => {
    const latestWatchedEpisodeForShow = await getLatestWatchedEpisodeForShow(
      showId
    );
    if (!latestWatchedEpisodeForShow) {
      const seasons = await getSeasons(showId);
      const firstSeason = seasons[0];
      const episodesFromSeason = await getEpisodes(firstSeason.id);
      const firstEpisode = episodesFromSeason[0];
      return Bookmark({
        ...firstEpisode,
        progress: 0,
        assetType: 'episode',
        resumeTime: 0
      });
    }
    const { playbackConfig } = await getConfiguration();
    if (
      latestWatchedEpisodeForShow.progress <
      playbackConfig.watchlistPercentageThreshold
    ) {
      return latestWatchedEpisodeForShow;
    }
    const seasons = await getSeasons(showId);

    const latestWatchedEpisodeSeason = seasons.find(
      season => season.id === latestWatchedEpisodeForShow.seasonId
    );
    const episodesFromCurrentSeason = await getEpisodes(
      latestWatchedEpisodeSeason.id
    );
    const nextEpisode = await getNextEpisode(
      latestWatchedEpisodeForShow,
      episodesFromCurrentSeason,
      seasons
    );
    if (nextEpisode) {
      return Bookmark({
        ...nextEpisode,
        progress: 0,
        assetType: 'episode',
        resumeTime: 0
      });
    }
    const firstSeason = seasons[0];
    const episodesFromSeason = await getEpisodes(firstSeason.id);
    const firstEpisode = episodesFromSeason[0];
    // Clear show related bookmark info
    await removeBookmark(showId);
    await removeLatestEpisode(showId);
    return Bookmark({
      ...firstEpisode,
      progress: 0,
      assetType: 'episode',
      resumeTime: 0
    });
    // Remove Watchlist item when reach last episode of last season
  };
  /**
   * Return the movie bookmark status, set progress to 0 when reached certain progress to reset.
   * @param {String} movieId - The id for the movie.
   * @returns {Bookmark} - The Movie to watch in the poper progress.
   */
  const getMoviePlayStatusBookmark = async movieId => {
    const bookmark = await getBookmark(movieId);
    if (!bookmark) {
      return undefined;
    }

    const { playbackConfig } = await getConfiguration();

    const { progress: currentProgress } = bookmark;

    if (currentProgress >= playbackConfig.watchlistPercentageThreshold) {
      return {
        ...bookmark,
        progress: 0,
        resumeTime: 0
      };
    }
    return bookmark;
  };

  return {
    addBookmark,
    addLatestWatchedEpisode,
    removeLatestEpisode,
    removeBookmark,
    clearBookmark,
    getBookmark,
    getBookmarks,
    getShowsBookmarks,
    isBookmark,
    getMoviePlayStatusBookmark,
    getNextEpisodeToPlayForShow,
    getLatestWatchedEpisodeForShow,
    getBookmarksForShow,
    getContinueWatchingItems
  };
};

export default bookmarkService;
