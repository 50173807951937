import * as vikimap from '@accedo/vdkweb-vikimap';

import config from '#/config';
import {
  SESSION_KEY,
  getControlClientSingleton
} from '#/providers/shared/control/control';

/**
 * @module providers/cms/control
 * @description
 * Provider CMS implementation from Accedo Control
 */

const appConfig = config.app;
let vikimapService = null;
let accedoOneClientInstance = null;
let configuration = null;
let menu = null;
let menuLocaleCode = null;
let initiated = false;

const CONFIG_KEYS_TOCHECK = ['defaultLocale', 'dictionary', 'mainMenuEntryId'];

const checkEmptyValue = (configKey, metadata) => {
  if (metadata[configKey] === undefined || metadata[configKey] === '') {
    if (appConfig[configKey] === undefined || appConfig[configKey] === '') {
      console.error(
        `[debug] Config Key ${configKey} is not set up, please review your local and remote config to add it`
      );
      return;
    }
    metadata[configKey] = appConfig[configKey];
  }
};

const checkEmptyValues = metadata => {
  CONFIG_KEYS_TOCHECK.forEach(configKey => {
    checkEmptyValue(configKey, metadata);
  });

  return metadata;
};

export const init = async () => {
  accedoOneClientInstance = await getControlClientSingleton;

  if (!vikimapService) {
    vikimapService = vikimap.getAccedoOneService(accedoOneClientInstance);
  }
  initiated = true;
};

/**
 *
 * Fetch the Configuration CMS content
 *
 * @returns {Configuration} The Configuration
 */
const getConfiguration = async () => {
  if (!initiated) {
    await init();
  }

  if (configuration) {
    return configuration;
  }

  const [
    { value: metadata },
    { value: assets },
    { value: status }
  ] = await Promise.allSettled([
    accedoOneClientInstance.getAllMetadata(),
    accedoOneClientInstance.getAllAssets(),
    accedoOneClientInstance.getApplicationStatus()
  ]);

  checkEmptyValues(metadata);
  configuration = {
    ...appConfig,
    ...status,
    ...metadata,
    assets
  };

  return configuration;
};

/**
 *
 * Fetch the Menu CMS content
 *
 * @param {String} id the Menu id
 * @param {any} options the Control options as defined in the Control documentation
 *
 * @returns {Menu} The Menu
 */
const getMenu = async ({ id, options }) => {
  if (!initiated) {
    await init();
  }
  if (menu && options.locale === menuLocaleCode) {
    return menu;
  }
  menu = await vikimapService.getMenu(id, options);
  menuLocaleCode = options?.locale || configuration?.defaultLocale;
  return menu;
};

const getRoutesMapping = async () => {
  if (!initiated) {
    await init();
  }
  const {
    routesMapping: routesMappingId,
    mainMenuEntryId
  } = await getConfiguration();

  const routesMappingParser = async routesMappingResults => {
    routesMappingResults.mappings = await vikimapService.getItemsByIds(
      routesMappingResults.mappings
    );
    const pageIds = routesMappingResults.mappings.map(map => map.page);
    const pageResponses = await vikimapService.getItemsByIds(pageIds);
    routesMappingResults.mappings.forEach(mapItem => {
      const pageResponse = pageResponses.find(
        pageResponseItem => pageResponseItem.id === mapItem.page
      );
      mapItem.page = pageResponse;
    });
    return routesMappingResults;
  };

  const [
    { value: routesMapping },
    { value: menuResponse }
  ] = await Promise.allSettled([
    vikimapService
      .getItem(routesMappingId)
      .then(routesMappingResults => routesMappingParser(routesMappingResults)),
    getMenu({ id: mainMenuEntryId })
  ]);

  if (menuResponse && routesMapping) {
    menuResponse.items.forEach(menuItem => {
      routesMapping.mappings.push(menuItem);
    });
  }
  return routesMapping?.mappings || [];
};

/**
 *
 * Fetch the Page CMS content
 *
 * @param {String} id the Page id
 * @param {any} options the Control options as defined in the Control documentation
 *
 * @returns {Page} The page
 */
const getPageLayout = async ({ id, options }) => {
  if (!initiated) {
    await init();
  }
  return vikimapService.getPage(id, options);
};

const getDefaultTheme = async () => {
  if (!initiated) {
    await init();
  }
  const { defaultTheme } = await getConfiguration();
  let theme;
  if (defaultTheme) {
    theme = await vikimapService.getItem(defaultTheme);
  }
  return theme;
};

/**
 *
 * Fetch the Page Theme CMS content
 *
 * @param {String} id the Page id
 * @param {any} options the Control options as defined in the Control documentation
 *
 * @returns {Page} The theme
 */
const getThemeByPage = async ({ id, options }) => {
  if (!initiated) {
    await init();
  }

  return accedoOneClientInstance.getEntryById(id, options).then(page => {
    if (!page.theme) {
      return;
    }
    return accedoOneClientInstance.getEntryById(page.theme, options);
  });
};

export const cleanAccedoOneSessionInClient = async () => {
  const { default: xdk } = await import('@accedo/xdk-core');
  await xdk.load();
  xdk.storage.unset(SESSION_KEY);
};

export default () => {
  return {
    init,
    getMenu,
    getRoutesMapping,
    getConfiguration,
    getPageLayout,
    getDefaultTheme,
    getThemeByPage
  };
};
