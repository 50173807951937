// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { withFocus } from '@accedo/vdkweb-navigation';
import { SearchIcon, UserIcon, SettingsIcon } from '#/components/Icons/Icons';
import Avatar from '#/components/Avatar';
import style from './menuItem.scss';

interface MenuItemProps {
  onClick: any;
  data: any;
  nav: any;
  userFullname?: string;
}

const icons = {
  search: () => <SearchIcon />,
  profile: () => <UserIcon />,
  settings: () => <SettingsIcon />
};

/**
 * MenuItem Component
 * @param {Object} props Component Props
 * @param {Function} props.onClick Callback function to dispatch when
 * @param {Object} props.data The content to be displayed
 * @param {Object} props.userFullname userFullname to be used in the avatar
 * @returns {React.ReactComponentElement} Component
 */
const MenuItem = ({ onClick, data, nav, userFullname }: MenuItemProps) => {
  const [content, setContent] = useState<any>(null);
  const [contentStyle, setContentStyle] = useState<string>('');

  const { pathname } = useLocation();

  const { image, itemGroup, viaIconLabel } = data;

  const isIcon = useCallback(() => !!viaIconLabel, [viaIconLabel]);

  const isProfile = useCallback(() => !!image, [image]);

  const isSelected = useCallback(() => pathname === data.route, [
    pathname,
    data.route
  ]);

  const getIcon = (iconType: 'search' | 'profile' | 'settings') =>
    icons[iconType];

  useEffect(() => {
    if (isProfile()) {
      setContent(
        <Avatar src={image} name={userFullname} isFocused={nav.isFocused} />
      );
      setContentStyle(style.avatar);
      return;
    }

    if (isIcon() && getIcon(viaIconLabel)) {
      setContent(getIcon(viaIconLabel));
      setContentStyle(style.icon);
      return;
    }

    setContent(data.displayText);
    setContentStyle(style.default);
  }, [nav.isFocused, viaIconLabel, data.displayText, image]);

  return (
    <div
      className={`${style.item} ${style[itemGroup]} ${
        nav.isFocused ? style.focused : ''
      } ${isSelected() ? style.selected : ''}`}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
    >
      <div className={contentStyle}>
        <button onClick={onClick}>{content}</button>
      </div>
    </div>
  );
};

export default withFocus(MenuItem);
