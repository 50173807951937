/**
 * Interpolate a String with {{keys}} with the passed object
 * @param {String} text with {{keys}} to be interpolated
 * @param {Object} object key/value objects to be interpolated in the text
 * @return {String} Interpolated String
 */
export default (text = '', object = {}) => {
  const parameters = text?.match(/[^{}]+(?=})/g);
  let interpolatedText = text;

  parameters?.forEach(parameter => {
    if (parameter && object && object[parameter]) {
      const value = object[parameter];

      interpolatedText = interpolatedText.replace(
        new RegExp(`{{${parameter}}}`),
        value
      );
    }
  });

  return interpolatedText;
};
