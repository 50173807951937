import EpgContentBlock, {
  EpgChannelEntry,
  Image,
  Content
} from '#/interfaces/live/EpgContentBlock';
import LiveProgram from '#/interfaces/live/LiveProgram';
import LiveProgramBuilder from './liveProgram';

const EpgChannelEntryModel = (rawEpgChannelEntry: any): EpgChannelEntry => {
  const {
    title,
    channelNumber,
    description,
    contents,
    metadata,
    images,
    categories,
    id,
    channelId,
    programs
  } = rawEpgChannelEntry;

  const getTitle = (): string => {
    return title;
  };
  const getChannelNumber = (): string => {
    return channelNumber;
  };
  const getDescription = (): string => {
    return description;
  };
  const getLanguage = (): string => {
    return contents;
  };
  const getContents = (): [Content] => {
    return metadata;
  };
  const getMetadata = (): [] => {
    return title;
  };
  const getImages = (): [Image] => {
    return images;
  };
  const getCategories = (): [] => {
    return categories;
  };
  const getId = (): string => {
    return id;
  };
  const getChannelId = (): string => {
    return channelId;
  };
  const getPrograms = (): [LiveProgram] => {
    const returnedPrograms = programs.map((program: any) =>
      new LiveProgramBuilder<LiveProgram>(program).create()
    );
    return returnedPrograms;
  };

  return {
    title: getTitle(),
    channelNumber: getChannelNumber(),
    description: getDescription(),
    language: getLanguage(),
    contents: getContents(),
    metadata: getMetadata(),
    images: getImages(),
    categories: getCategories(),
    id: getId(),
    channelId: getChannelId(),
    programs: getPrograms()
  };
};

const EpgContentBlockModel = (rawEpgContentBlock: any): EpgContentBlock => {
  const { entries } = rawEpgContentBlock;

  const getEntries = (): [EpgChannelEntry] => {
    return entries.map((entry: any) => EpgChannelEntryModel(entry));
  };

  return {
    entries: getEntries()
  };
};

class EpgContentBlockBuilder<T> {
  rawEpgContentBlock: T;

  constructor(rawEpgContentBlock: T) {
    this.rawEpgContentBlock = rawEpgContentBlock;
  }

  /**
   * Return/create the final EpgContentBlock object.
   * @returns {EpgContentBlock} "this" - Current EpgContentBlockBuilder instance.
   */
  create(): EpgContentBlock {
    const rawEpgContentBlock: EpgContentBlock = EpgContentBlockModel(
      this.rawEpgContentBlock
    );
    return rawEpgContentBlock;
  }
}

export default EpgContentBlockBuilder;
